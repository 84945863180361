import React from 'react';
import Container from 'components/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Styles from './RoadmapTab.styles';
import { TabTitle } from './Styles';
import Disclaimer from '../components/Disclaimer';

const content = [
  {
    id: 1,
    title: 'Q2. 2022',
    subtitle: 'Finding a property',
    status: 'COMPLETED!',
    content:
      'We found a very attractive property with a central location, Grejsdalsvej 55 in Vejle, Denmark.',
    milestoneColor: '#3F826D',
  },
  {
    id: 2,
    title: 'Q2 - Q1 2023',
    subtitle: 'GREV 55 project and construction permits',
    status: 'COMPLETED!',
    content:
      // eslint-disable-next-line quotes
      "The existing property with three units will be converted into five units. All construction permits are approved by the city's municipality.",

    milestoneColor: '#DDC16E',
  },
  {
    id: 3,
    title: 'Q1 - Q2 2023',
    subtitle: 'Property tokenization',
    status: 'COMPLETED!',
    content:
      'We have tokenized the entire property into 2.502.500 GREV 55 digital shares and 247.500 non-tokenized shares.',
    milestoneColor: '#64B4E5',
  },
  {
    id: 4,
    title: 'Q2 2023 - Q2 2024',
    subtitle: 'Public sale',
    status: 'ONGOING!',
    content:
      '2.200.000 of the GREV 55 tokens are sold to anyone interested in becoming a tokenized real estate owner.',
    milestoneColor: '#1C5695',
  },
  {
    id: 5,
    title: 'UPCOMING',
    subtitle: 'Trading',
    status: '',
    content:
      'The aftermarket opens and investors can begin to trade their GREV 55 tokens. Also, new investors can purchase GREV 55 tokens from the existing token owners.',
    milestoneColor: '#755884',
  },
  {
    id: 6,
    title: 'UPCOMING',
    subtitle: 'Renovation of the property',
    status: '',
    content:
      'Five apartments with a total of 396 m2 of living space with modern design and functionality are being built.',
    milestoneColor: '#3F2A62',
  },
  {
    id: 7,
    title: 'UPCOMING',
    subtitle: 'Investors will begin to receive dividends from the project.',
    status: '',
    content:
      'We have estimated that with current market conditions, it could take up to 18 months to complete the renovation. However, we hope to complete the project ahead of schedule.',
    milestoneColor: '#D66EA1',
  },
];

const RoadmapTab = (): JSX.Element => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isBlue = (text: string) => text === 'UPCOMING';
  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <Styles.ContentBox>
        <TabTitle>Roadmap</TabTitle>

        {upMd && <Styles.ContentContainer />}

        {content.map((card, index) => (
          <Styles.ContentContainer
            key={card.id}
            sx={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }}
          >
            <Styles.InfoBox
              sx={{ textAlign: index % 2 === 0 ? 'right' : 'left' }}
            >
              <Styles.Title
                component="h1"
                color={isBlue(card.title) ? 'primary' : 'text.primary'}
              >
                {card.title}
              </Styles.Title>
              <Styles.Subtitle component="h2" color="text.primary">
                <div>
                  {card.subtitle + ' '}
                  <span style={{ color: '#3F826D' }}>{card.status}</span>
                </div>
              </Styles.Subtitle>
              <Styles.InfoText component="p">{card.content}</Styles.InfoText>
            </Styles.InfoBox>
            <Styles.MidBox>
              <Styles.CircleMilestone
                sx={{ backgroundColor: card.milestoneColor }}
              />
              <Styles.DashedLine />
            </Styles.MidBox>
            <Styles.SideBox />
          </Styles.ContentContainer>
        ))}
        <Disclaimer />
      </Styles.ContentBox>
    </Container>
  );
};

export default RoadmapTab;
